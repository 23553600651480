<template>
  <b-card body>
    <div class="">
      <b-button
        variant="primary"
        size="sm"
        class="mr-1 rounded-lg p-0"
        @click="$router.go(-1)"
      >
        <feather-icon
          size="2x"
          icon="ChevronLeftIcon"
        />
      </b-button>
      <h4 class="font-semibold text-black d-inline-flex mb-0">
        Pengajuan Berlangganan
      </h4>
    </div>
    <div class="d-flex flex-column border mt-2 mb-2 p-2 text-black space-y-5">
      <div class="d-flex gap-2">
        <div class="font-semibold text-lg">
          Mitra Gudang
        </div>
        <div class="">
          <BBadge
            :variant="handleStatusBadge('badge', detail.submission_status)"
          >
            {{ handleStatusBadge('text', detail.submission_status) }}
          </BBadge>
        </div>
      </div>
      <div class="w-100 d-flex">
        <div class="d-flex flex-row items-center">
          <div class="mr-1">
            <b-avatar
              :src="detail.warehouse_logo_url"
            />
          </div>
          <div class="d-flex flex-column items-start">
            <div class="font-semibold">
              {{ detail.warehouse_name }}
            </div>
            <div class="">
              {{ formatDate(detail.warehouse_join_date) }}
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex">
        <div class="w-25 px-0">
          Owner
        </div>
        <div class="w-75 px-0">
          {{ detail.owner }}
        </div>
      </div>
      <div class="w-100 d-flex">
        <div class="w-25 px-0">
          Status
        </div>
        <div class="w-75 px-0 d-flex">
          <div :class="handleAvailability(detail.warehouse_availability)">
            {{ detail.warehouse_availability }}
          </div>
        </div>
      </div>
      <div class="w-100 d-flex">
        <div class="w-25 px-0">
          Alamat Gudang
        </div>
        <div class="w-75 px-0">
          {{ detail.detail_address }}
        </div>
      </div>
      <div class="w-100 d-flex flex-row-reverse mt-4">
        <div class="w-50 d-flex gap-7">
          <BButton
            v-if="detail.submission_status !== 'Sedang Diajukan'"
            class="w-100"
            variant="outline-primary"
            @click="chatButton(detail.warehouse_phone)"
          >
            Chat
          </BButton>
          <BButton
            v-else
            class="w-100"
            disabled
          >
            Chat
          </BButton>
          <BButton
            class="w-100"
            variant="outline-primary"
            @click="handleInfoGudang(detail.mitra_id)"
          >
            Lihat Info Gudang
          </BButton>
        </div>
      </div>
    </div>
    <div class="font-semibold text-black text-lg mb-[20px]">
      Daftar Produk
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="detail.product_submission.map(item => ({...item, _showDetails: item.is_bundling === 1}))"
        :fields="fields"
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        show-empty
      >
        <template #cell(nama)="data">
          <div class="d-flex items-start gap-2 text-black">
            <b-img
              :src="data.item.images_path ? data.item.images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
              fluid
              class="image-product"
            />
            <div class="d-block">
              <BBadge
                v-if="(data.item.is_reject === 1)"
                variant="light-danger"
              >Ditolak</BBadge>
              <div class="font-medium mt-[5px]">
                {{ data.item.product_name }}
              </div>
              <div class="text-primary text-[12px]">
                SKU : {{ data.item.sku }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(volume)="data">
          <div
            style="min-width: 65px; !important"
          >
            P : {{ data.item.product_length }} cm
            <br>
            L : {{ data.item.product_width }} cm
            <br>
            T : {{ data.item.product_height }} cm
          </div>
        </template>
        <template #cell(harga_barang)="data">
          <div>
            {{ data.item.range_price }}
          </div>
        </template>
        <template #head(jenis_bahan_packing)="data">
          <div class="d-flex align-items-center">
            {{ data.label }}
            <div class="ml-[4px]">
              <img
                id="packingLayerPopover"
                src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                alt="Komerce"
              >
              <b-popover
                triggers="hover"
                target="packingLayerPopover"
                placement="top"
              >
                Angka dibelakang jenis bahan packing
                merupakan jumlah lapis packing
              </b-popover>
            </div>
          </div>
        </template>
        <template #cell(jenis_bahan_packing)="data">
          <div style="white-space: pre-line;">
            {{ handleMultiplePacking(data.item.packing_detail) }}
          </div>
        </template>
        <template #cell(biaya_fulfillment)="data">
          <div v-if="(data.item.fulfillment_cost !== null)">
            Rp. {{ formatRupiah(data.item.fulfillment_cost) }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #head(biaya_tambahan)="data">
          <div class="d-flex align-items-center">
            {{ data.label }}
            <div class="ml-[4px]">
              <img
                id="addonPopover"
                src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                alt="Komerce"
              >
              <b-popover
                triggers="hover"
                target="addonPopover"
                placement="top"
              >
                Biaya fullfilement untuk penambahan quantity dari suatu barang dalam 1 resi
              </b-popover>
            </div>
          </div>
        </template>
        <template #cell(biaya_tambahan)="data">
          <div v-if="(data.item.addon_cost !== null)">
            Rp. {{ formatRupiah(data.item.addon_cost) }} / {{ data.item.addon_minimum }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #row-details="data">
          <b-row
            class="pl-[3.2rem] -mt-[1rem]"
          >
            <b-col cols="12">
              <div class="flex">
                <div
                  v-b-toggle="`bundling-${String(data.index)}`"
                  class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                    alt="bundling"
                    width="16"
                  >
                  <div>Bundling</div>
                </div>
              </div>
              <b-collapse
                :id="`bundling-${String(data.index)}`"
              >
                <div class="border-l-[2px] h-min p-[16px] ml-[3.5rem]">
                  <div class="border rounded-t w-[80%]">
                    <div class="bg-[#FFECE9] py-[8px] px-[28px] rounded-t text-black font-[500]">
                      Produk
                    </div>
                    <b-table
                      :items="data.item.bundle_component"
                      :fields="fieldsBundling"
                    >
                      <template #cell(no)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(product_name)="data">
                        <div class="flex gap-[16px] items-start">
                          <img
                            :src="data.item.product_image[0] ? data.item.product_image[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                            alt="product"
                            width="52"
                            height="52"
                          >
                          <div class="space-y-[4px]">
                            <div class="font-medium">
                              {{ data.item.product_name }}
                            </div>
                            <div class="text-primary text-[12px]">
                              SKU: {{ data.item.sku }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
                <div class="flex ml-[0.8rem]">
                  <div
                    v-b-toggle="`bundling-${String(data.index)}`"
                    class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#F95031] text-white font-[500] cursor-pointer"
                  >
                    <feather-icon
                      icon="ChevronUpIcon"
                    />
                    <div>Tutup</div>
                  </div>
                </div>
              </b-collapse>
            </b-col>
          </b-row>
        </template>
      </BTable>
    </BOverlay>
  </b-card>
</template>

<script>
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import chatButton from '@/libs/chatButton'

export default {
  name: 'DetailRiwayatBerlangganan',
  data() {
    return {
      chatButton,
      detail: {},

      isEdit: false,

      fields: [
        {
          key: 'nama',
          label: 'Nama',
          thClass: 'text-black text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black',
        },
        {
          key: 'volume',
          label: 'Volume',
          thClass: 'text-black text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
        {
          key: 'harga_barang',
          label: 'Harga Barang',
          thClass: 'text-black text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
        {
          key: 'jenis_bahan_packing',
          label: 'Jenis Bahan Packing',
          thClass: 'text-black text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
        {
          key: 'biaya_fulfillment',
          label: 'Biaya Fulfillment',
          thClass: 'text-black text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
        {
          key: 'biaya_tambahan',
          label: 'Biaya Tambahan',
          thClass: 'text-black text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
      ],

      fieldsBundling: [
        {
          key: 'no',
          label: 'No',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
          tdClass: 'text-black',
        },
        {
          key: 'product_name',
          label: 'Nama Produk',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
          tdClass: 'text-black',
        },
        {
          key: 'qty',
          label: 'Jumlah',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize text-center bg-white',
          tdClass: 'text-black text-center',
        },
      ],

      products: null,
      history: null,
    }
  },

  created() {
    this.fetchDetailBerlangganan()
  },

  methods: {
    handleMultiplePacking(value) {
      return value.map(obj => `${obj.packing_material} : ${obj.packing_layer}`).join('\n')
    },

    handleInfoGudang(id) {
      this.$router.push({
        path: `/search-gudang/detail/${id}`,
      })
    },

    handleStatusBadge(part, status) {
      if (part === 'badge') {
        if (status === 'Sedang Diajukan') {
          return 'light-warning'
        }
        if (status === 'Disetujui') {
          return 'light-success'
        }
        return 'light-danger'
      }
      return status
    },

    handleAvailability(status) {
      if (status === 'Tersedia') {
        return 'status-tersedia'
      }
      return 'status-penuh'
    },

    setQuantity(status, product, index) {
      if (status === 'plus') {
        this.detailInbound.products[product].variant[index].total_inbound += 1
      } else if (status === 'minus') {
        this.detailInbound.products[product].variant[index].total_inbound -= 1
      }
    },

    fetchDetailBerlangganan() {
      this.loading = true
      this.$http_komship.get(`/v1/komship/submission/history/${this.$route.params.id}/detail`)
        .then(response => {
          const { data } = response.data
          this.detail = data
          this.loading = false
        }).catch(() => {
          this.loading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },

    shippingMethods(part, shipping) {
      if (part === 'img') {
        if (shipping != null) {
          return 'https://storage.googleapis.com/komerce/assets/menggunakan-ekspedisi-orange.svg'
        }
        return 'https://storage.googleapis.com/komerce/assets/dikirim-sendiri-orange.svg'
      }
      if (shipping != null) {
        return 'Menggunakan Ekspedisi'
      }
      return 'Dikirim Sendiri'
    },

    statusColor(status) {
      if (status === 'Proses') {
        return 'text-warning'
      } if (status === 'Selesai') {
        return 'text-success'
      }
      return 'text-danger'
    },
    formatDate(value) {
      return moment(value).format('DD MMMM YYYY')
    },

    formatDateManifest(value) {
      return moment(value).format('DD MMM')
    },
    formatTimeManifest(value) {
      return value.replace(/:[^:]*$/, '')
    },
    ellipseColor(value) {
      if (value === '5') {
        return 'https://storage.googleapis.com/komerce/assets/tracking-ellipse-orange.svg'
      }
      return 'https://storage.googleapis.com/komerce/assets/tracking-elipse.svg'
    },

    confirmBatalkan(data) {
      this.$swal({
        text: 'Anda yakin melakukan pembatalan inbound?',
        title: 'Batalkan Berlangganan',
        icon: 'warning',
        iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
        showCancelButton: true,
        cancelButtonText: 'Tidak',
        confirmButtonText: 'Ya',
        customClass: {
          icon: 'border-0 w-50 my-5',
          confirmButton: 'btn btn-primary mr-1 px-5',
          cancelButton: 'btn btn-outline-primary px-4',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.batalkanBerlangganan(data)
        }
      })
    },

    formatRupiah(value) {
      return new Intl.NumberFormat('id-ID').format(value)
    },

    batalkanBerlangganan(data) {
      this.$http_komship.put(`/v1/komship/inbound/cancel/${data}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Success batalkan inbound',
              variant: 'success',
            },
          }, 2000)
          this.$router.go(-1)
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal batalkan inbound, silahkan coba lagi!',
              variant: 'danger',
            },
          }, 2000)
          this.$router.refresh()
        })
    },
  },
}
</script>

 <style scoped>
 .image-product {
   object-fit: cover;
   object-position: center center;
   width: 38px!important;
   height: 38px!important;
 }

 .status-tersedia {
  background-color: #DFF3FF;
  padding: 5px 10px;
  border-radius: 4px;
  color: #08A0F7;
 }
 .status-penuh {
  background-color: #FFEDED;
  padding: 5px 10px;
  border-radius: 4px;
  color: #E31A1A;
 }
 </style>
